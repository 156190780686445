/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 09:13:58
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:42:07
 */
import { getToday, numberFormat } from '@/plugins/utils'
import EnumMap from '@/plugins/enumMap'
import { mapGetters } from 'vuex'
export default {
  name: 'OperateActivityTable',
  data () {
    return {
      date: [],
      queryForm: {
        status: 0,
        start_date: '',
        end_date: '',
        field_type: '',
        sort_type: ''
      },
      media_platform_list: [
        {
          name: '全部状态',
          id: ''
        },
        {
          name: '启用',
          id: 1
        },
        {
          name: '停用',
          id: 2
        }
      ]
    }
  },
  watch: {
    date (val, old) {
      if (val) {
        this.$set(this.queryForm, 'start_date', val[0])
        this.$set(this.queryForm, 'end_date', val[1])
      }
    }
  },
  computed: {
    ...mapGetters([
      'enum_map'
    ])
  },
  methods: {
    tableSearch (reset, sort) {
      if (!sort) {
        this.queryForm.field_type = ''
        this.queryForm.sort_type = ''
      }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    detail (id) {
      this.$router.push({ path: `/operate/activity/detail/${id}` })
    },
    tableSort (val) {
      this.queryForm.field_type = val.fieldType
      this.queryForm.sort_type = val.sort_type
      this.tableSearch(true, true)
    },
    numFormat (val) {
      return numberFormat(val)
    },
    activityStatus (val) {
      const name = new EnumMap('activity_status').mapping(val)
      return name
    }
  },
  created () {
    this.date = getToday()
  }
}
