/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 21:24:31
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 19:45:41
 */
import ActivityTable from './ActivityTable'
export default {
  name: 'OperateActive',
  components: {
    ActivityTable
  },
  data () {
    return {
    }
  },
  methods: {

  }
}
